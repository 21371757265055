var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticStyle:{"transition":"top 0.5s ease 0s"},style:(_vm.offsetTop > 55
				? 'background-color:rgba(255,255,255,0.9)!important;'
				: 'background-color:#fff;'),attrs:{"height":_vm.barHeight,"flat":"","fixed":""}},[_c('div',{staticClass:"d-flex align-center mx-auto",staticStyle:{"width":"100%","max-width":"900px"}},[_c('v-toolbar-title',{staticClass:"px-1"},[_c('img',{attrs:{"width":"45","src":require("@/assets/images/tella_color.png"),"alt":""}})]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"align-center"},[_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"href":"https://my.tella.co.kr","color":"grey","outlined":"","small":_vm.userDevice === 'mobile'}},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('menu.sign-in')))])])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"small":_vm.userDevice === 'mobile',"href":"https://my.tella.co.kr/trial","color":"primary","depressed":""}},[_c('strong',{staticClass:"font-weight-bold white--text"},[_vm._v(_vm._s(_vm.$t('menu.trial')))])])],1)])],1)]),(false)?_c('div',{staticClass:"white px-3 py-2 text-body-1 d-flex align-center d-block d-sm-none",staticStyle:{"border-top":"1px solid #f0f0f0 !important","width":"100%","position":"fixed","z-index":"3","transition":"top 0.5s ease 0s"},style:(("top:" + (_vm.isBarHide === true ? 0 : _vm.barHeight) + "px"))},[_c('div',{staticClass:"mr-5 font-weight-regular",class:{
				'primary--text font-weight-bold': _vm.mobileActiveMenu === 'dont-give-up',
			},attrs:{"text":""},on:{"click":function($event){return _vm.scrollTo('#dont-give-up')}}},[_vm._v(" Why Tella ")]),_c('div',{staticClass:"mr-5 text-capitalize font-weight-regular",class:{
				'primary--text font-weight-bold':
					_vm.mobileActiveMenu === 'you-can-do-it',
			},attrs:{"text":""},on:{"click":function($event){return _vm.scrollTo('#you-can-do-it')}}},[_vm._v(" 후기 ")]),_c('div',{staticClass:"text-capitalize font-weight-regular",class:{
				'primary--text font-weight-bold': _vm.mobileActiveMenu === 'price',
			},attrs:{"text":""},on:{"click":function($event){return _vm.scrollTo('#price')}}},[_vm._v(" 가격 ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }