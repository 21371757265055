<template>
	<div>
		<v-app-bar
			v-resize="onResize"
			:height="barHeight"
			flat
			fixed
			style="transition: top 0.5s ease 0s"
			v-scroll="onScroll"
			:style="
				offsetTop > 55
					? 'background-color:rgba(255,255,255,0.9)!important;'
					: 'background-color:#fff;'
			"
		>
			<!-- :style="isBarHide === true ? 'top:-55px' : ''" -->
			<!-- -->
			<div
				class="d-flex align-center mx-auto"
				style="width: 100%; max-width: 900px"
			>
				<v-toolbar-title class="px-1">
					<img width="45" src="@/assets/images/tella_color.png" alt="" />
				</v-toolbar-title>
				<!-- <v-toolbar-items class="ml-5 align-center">
					<v-btn
						@click="scrollTo('#dont-give-up')"
						large
						:color="
							mobileActiveMenu === 'dont-give-up' ? 'primary' : 'grey darken-4'
						"
						class="text-capitalize d-none d-sm-flex"
						text
					>
						<span class="text-body-1">Why Tella</span>
					</v-btn>
					<v-btn
						@click="scrollTo('#you-can-do-it')"
						:color="
							mobileActiveMenu === 'you-can-do-it' ? 'primary' : 'grey darken-4'
						"
						class="text-capitalize d-none d-sm-flex"
						text
					>
						<span class="text-body-1">후기</span>
					</v-btn>
					<v-btn
						@click="scrollTo('#price')"
						:color="mobileActiveMenu === 'price' ? 'primary' : 'grey darken-4'"
						class="text-capitalize d-none d-sm-flex"
						text
					>
						<span class="text-body-1">가격</span>
					</v-btn>
				</v-toolbar-items> -->
				<v-spacer></v-spacer>

				<v-toolbar-items class="align-center">
					<div>
						<v-btn
							href="https://my.tella.co.kr"
							color="grey"
							outlined
							class="mr-1"
							:small="userDevice === 'mobile'"
						>
							<span class="grey--text text--darken-2">{{
								$t('menu.sign-in')
							}}</span>
						</v-btn>
					</div>
					<div style="position: relative">
						<v-btn
							:small="userDevice === 'mobile'"
							href="https://my.tella.co.kr/trial"
							color="primary"
							depressed
						>
							<strong class="font-weight-bold white--text">{{
								$t('menu.trial')
							}}</strong>
						</v-btn>
					</div>
				</v-toolbar-items>
			</div>
			<!-- <div
				v-show="isBarHide === false"
				class="text-center tooltip"
				:style="tooltipStyle"
			>
				10% 할인 혜택&nbsp; 🎁
				<div class="chat-tail"></div>
			</div> -->
		</v-app-bar>
		<!-- v-if="userDevice === 'mobile'" -->
		<div
			v-if="false"
			class="white px-3 py-2 text-body-1 d-flex align-center d-block d-sm-none"
			style="
				border-top: 1px solid #f0f0f0 !important;
				width: 100%;
				position: fixed;
				z-index: 3;
				transition: top 0.5s ease 0s;
			"
			:style="`top:${isBarHide === true ? 0 : barHeight}px`"
		>
			<div
				text
				class="mr-5 font-weight-regular"
				:class="{
					'primary--text font-weight-bold': mobileActiveMenu === 'dont-give-up',
				}"
				@click="scrollTo('#dont-give-up')"
			>
				Why Tella
			</div>
			<div
				text
				class="mr-5 text-capitalize font-weight-regular"
				:class="{
					'primary--text font-weight-bold':
						mobileActiveMenu === 'you-can-do-it',
				}"
				@click="scrollTo('#you-can-do-it')"
			>
				후기
			</div>
			<div
				text
				class="text-capitalize font-weight-regular"
				:class="{
					'primary--text font-weight-bold': mobileActiveMenu === 'price',
				}"
				@click="scrollTo('#price')"
			>
				가격
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			scrollToUp: false,
			offsetTop: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			mobileActiveMenu: null,
			isScrolling: true,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		isBarHide() {
			if (this.userDevice === 'desktop') return false
			// 맨 위에 있으면 보여짐
			if (this.offsetTop <= 55) return false
			// 올리면 보여짐
			return this.scrollToUp === true ? false : true
		},
		barHeight() {
			return this.userDevice === 'mobile' ? 55 : 70
		},
		tooltipStyle() {
			const leftPosition =
				this.windowSize.x <= 900
					? 'right: 15px;'
					: 'left: 50%;	margin-left:330px;'
			const topPosition =
				this.userDevice === 'mobile' ? 'top: 55px;' : 'top: 60px;'
			return leftPosition + topPosition
		},
		subMenuStyle() {
			return 'position: sticky; top: 0px; '
		},
	},
	mounted() {
		this.onResize()
	},
	methods: {
		scrollTo(selector) {
			this.isScrolling = true

			this.$vuetify.goTo(selector, {
				offset: this.userDevice === 'desktop' ? this.barHeight - 1 : 45,
				duration: 1500,
			})
			setTimeout(() => {
				this.isScrolling = false
			}, 2000)
		},
		onScroll() {
			const oldOffsetTop = this.offsetTop
			this.offsetTop = document.querySelector('html').scrollTop
			this.menuActiveControl()
			this.barShowControl(oldOffsetTop)
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		},
		menuActiveControl() {
			let whyTellaEl = document.querySelector('#dont-give-up').offsetTop
			let youCanDoItEl = document.querySelector('#you-can-do-it').offsetTop
			let price = document.querySelector('#price').offsetTop

			this.mobileActiveMenu =
				price < this.offsetTop
					? 'price'
					: youCanDoItEl <= this.offsetTop
					? 'you-can-do-it'
					: whyTellaEl <= this.offsetTop
					? 'dont-give-up'
					: null
		},
		barShowControl(oldOffsetTop) {
			// this.scrollToDown = false
			if (oldOffsetTop - this.offsetTop > 20) {
				if (this.isScrolling === true) return
				this.scrollToUp = true
			}
			if (oldOffsetTop - this.offsetTop < -20) {
				this.scrollToUp = false
			}
		},
	},
}
</script>

<style scoped lang="scss">
.tooltip {
	position: absolute;
	z-index: 10;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 10px;
	width: 110px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	padding-top: 10px;
	font-weight: bold;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		top: -5px;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 10px solid #353535;
	}
}
</style>
