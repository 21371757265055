<template>
	<v-app>
		<AppBar v-if="language === 'ko'" />
		<v-app-bar
			height="60"
			width="890"
			class="mx-auto"
			flat
			color="white"
			v-if="language === 'ja'"
		>
			<!-- -->
			<v-app-bar-nav-icon
				@click="drawer = !drawer"
				v-if="userDevice === 'mobile'"
			></v-app-bar-nav-icon>
			<v-toolbar-title class="d-none d-sm-flex">
				<v-img src="@/assets/images/logo.png" alt=""></v-img>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items class="align-center">
				<a
					href="https://my.tella.co.kr/plans"
					class="d-none d-sm-flex bar-button"
					text
				>
					<span class="">{{ $t('menu.plan') }}</span>
				</a>
				<a
					href="https://tella.notion.site/JA-7389d9d3c241420b82815fa225d28b06"
					target="_blank"
					class="d-none d-sm-flex bar-button"
					text
				>
					<span class="">{{ $t('menu.faq') }}</span>
				</a>
				<a
					href="https://my.tella.co.kr/sign-in"
					class="d-none d-sm-flex bar-button"
					text
				>
					<span class="primary--text">{{ $t('menu.sign-in') }}</span>
				</a>
				<div>
					<v-btn
						href="https://my.tella.co.kr/trial-jp"
						color="primary"
						class="d-none d-sm-flex bar-button"
						depressed
					>
						<span class="white--text">{{ $t('menu.trial') }}</span>
					</v-btn>
				</div>
			</v-toolbar-items>
		</v-app-bar>
		<v-main
			id="tella"
			:style="`padding-top: ${language === 'ko' ? barHeight : 0}px`"
		>
			<router-view />
		</v-main>

		<v-navigation-drawer
			width="320"
			v-model="drawer"
			app
			temporary
			right
			class="pr-3 pt-4 text-right"
		>
			<v-btn
				@click="drawer = !drawer"
				icon
				text
				color="grey darken-1"
				class="mb-10"
				><v-icon>mdi-window-close</v-icon></v-btn
			>
			<div class="pr-2">
				<div class="d-flex justify-end mb-13 pr-3">
					<v-spacer></v-spacer>
					<v-img src="@/assets/images/logo.png" max-width="65"></v-img>
				</div>
				<div>
					<v-btn
						href="https://my.tella.co.kr/plans"
						large
						text
						color="grey darken-1"
						class="mb-3"
						height="25"
						><span class="drwaer-button">{{ $t('menu.plan') }}</span>
					</v-btn>
				</div>
				<div>
					<v-btn
						href="https://tella.notion.site/JA-7389d9d3c241420b82815fa225d28b06"
						target="_blank"
						large
						text
						color="grey darken-1"
						class="mb-3"
						height="25"
						><span class="drwaer-button">{{ $t('menu.faq') }}</span>
					</v-btn>
				</div>
				<div>
					<v-btn
						href="https://tella.channel.io/support-bots/37625"
						target="_blank"
						large
						text
						color="grey darken-1"
						class="mb-3"
						height="25"
						><span class="drwaer-button">{{ $t('menu.cs') }}</span>
					</v-btn>
				</div>
				<div class="mb-8">
					<v-btn
						href="https://my.tella.co.kr/sign-in"
						large
						text
						color="grey darken-1"
						class="mb-3"
						height="25"
						><span class="drwaer-button primary--text">{{
							$t('menu.sign-in-and-sign-up')
						}}</span>
					</v-btn>
				</div>
				<div>
					<v-btn
						href="https://my.tella.co.kr/trial-jp"
						depressed
						x-large
						color="primary"
						class="font-weight-bold"
						>{{ $t('menu.trial') }}</v-btn
					>
				</div>
			</div>
		</v-navigation-drawer>

		<!-- <BottomSheet /> -->
	</v-app>
</template>

<script>
import AppBar from './components/AppBar'
// import BottomSheet from './components/BottomSheet'

export default {
	components: {
		AppBar,
		// BottomSheet,
	},
	name: 'App',
	data() {
		return {
			offsetTop: 0,
			drawer: false,
			windowSize: {
				x: 0,
				y: 0,
			},
			mobileActiveMenu: null,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		barHeight() {
			return this.userDevice === 'mobile' ? 55 : 70
		},
		tooltipStyle() {
			const leftPosition =
				this.windowSize.x <= 900
					? 'right: 15px;'
					: 'left: 50%;	margin-left:330px;'
			const topPosition =
				this.userDevice === 'mobile' ? 'top: 55px;' : 'top: 60px;'
			return leftPosition + topPosition
		},
		language() {
			return this.$store.getters['language']
		},
	},
	mounted() {
		this.onResize()
	},

	created() {
		this.detectDevice()
	},

	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		scrollTo(selector) {
			this.$vuetify.goTo(selector, {
				offset: this.barHeight,
			})
		},
		onScroll() {
			this.offsetTop = document.querySelector('html').scrollTop
			let whyTellaEl = document.querySelector('#dont-give-up').offsetTop
			let youCanDoItEl = document.querySelector('#you-can-do-it').offsetTop
			let price = document.querySelector('#price').offsetTop

			this.mobileActiveMenu =
				price < this.offsetTop
					? 'price'
					: youCanDoItEl <= this.offsetTop
					? 'you-can-do-it'
					: whyTellaEl <= this.offsetTop
					? 'dont-give-up'
					: null
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		},
		detectDevice() {
			;/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
				? this.$store.commit('setUserDevice', 'mobile')
				: this.$store.commit('setUserDevice', 'desktop')
		},
	},
}
</script>

<style lang="scss">
@import url('./assets/css/main.css');
/* @import url('./assets/css/color.scss'); */
@import url('./assets/css/typography.css');
@import url('./assets/css/button.css');
@import url('./assets/css/form.css');

.sub--title {
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.9px;
}
.main--title {
	font-size: 30px;
	font-weight: normal;
	letter-spacing: -1.5px;
	color: #1a1a1a;
}

@media screen and (max-width: 600px) {
	.main--title {
		font-size: 28px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -1.4px;
		text-align: center;
		color: #1a1a1a;
	}
}

.drwaer-button {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.571429px;
	color: #1a1a1a;
}
.bar-button {
	padding: 0 15px;
	margin: 0 10px;
	text-decoration: unset;

	span {
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		/* or 131% */

		display: flex;
		letter-spacing: -0.571429px;

		color: #1a1a1a;
	}
}
</style>

<style scoped lang="scss">
.tooltip {
	position: absolute;
	z-index: 10;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 10px;
	width: 110px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	padding-top: 10px;
	font-weight: bold;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		top: -5px;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 10px solid #353535;
	}
}
</style>
