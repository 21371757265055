import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Home.vue'),
	},
	{
		path: '/:language',
		name: 'Landing',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Landing.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
